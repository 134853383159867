
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>
    <b-card title="Informations générales">
       <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
              required
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Photo"
              label-for="Photo"
            >
            
            <b-media vertical-align="top" v-if="file!==null">
                  <template #aside>
                    <b-img
                      :src="file"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-warning"
                      class="btn-icon"
                      @click="editPhoto()"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
            required
                v-model="file"
                :state="Boolean(file)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAdded($event)"
            />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Fichier 3D"
              label-for="Photo"
            >
            
            <b-media vertical-align="top" v-if="file_3d!==null">
                  <template #aside>
                    <b-img
                      :src="file_3d"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-warning"
                      class="btn-icon"
                      @click="editFile()"
                    >
                      <feather-icon icon="EditIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
            required
                v-model="file_3d"
                :state="Boolean(file_3d)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="file3DAdded($event)"
            />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Prix"
              label-for="Prix"
            >
            <validation-provider
              #default="{ errors }"
              name="Prix"
              rules="required"
            >
              <b-form-input
              required
                id="Prix"
                v-model="form.price"
                :plaintext="readOnly"
                :state="errors.length > 0 ? false:null"
                type="number"
                step="0.01"
                placeholder="Prix"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Texture"
              label-for="Texture"
            >
            <validation-provider
              #default="{ errors }"
              name="Texture"
              rules="required"
            >
              <v-select
                v-model="form.texture"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :state="errors.length > 0 ? false:null"
                :options="textureChoices"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      </validation-observer>

    </b-card>

  <b-button v-if="edition==false"
     @click="createTextureStyle"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click="updateTextureStyle"
    >
    Enregistrer
    </b-button>

  </div>
</b-overlay>
</template>

<script>
import { BButton,BFormInput, BOverlay, BForm, BRow, BCol, BFormGroup,BFormFile, BCard, BFormCheckbox, BFormTextarea,BCardText, BMedia, BImg} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest,Utils} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'

export default {
  name: 'TextureStyleForm',
  components: {
    BButton,
    BFormInput,
    BOverlay,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BCardText, 
    BMedia, 
    BImg,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
          currentBase64:null,
          loading:true,
          file: null,
          textures:[],
          textureChoices:[],
          edition:false,
          file_3d:null,
          form:{
            name:"",
            file:'',
            price:0,
            photo:'',
            texture:null
          },
      }
  },
  props: ['texture_style', 'readOnly'],
  methods:{
    editPhoto() {
      this.file=null
    },
    editFile() {
      this.file_3d=null
    },
    async getTextures() {
      let textures = await APIRequest.list('textures')
      this.textures=textures[0];
      this.textureChoices=Utils.formatSelect(this.textures,'name','id')
      
    },
    async createTextureStyle()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        if(this.form.texture!=null)
        {
          let texture_id = this.form.texture.value
          this.form.texture={}
          this.form.texture.id=texture_id
        }
        try {
          
          await APIRequest.create('texture-styles',this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Le style de texture a bien été créée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'texture-styles'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
        }
      })
      
    },
    async updateTextureStyle()
    {
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
        this.loading=true
        if(this.form.texture!=null)
        {
          if(this.form.texture!=null)
          {
            let texture_id = this.form.texture.value
            this.form.texture={}
            this.form.texture.id=texture_id
          }
        }
        try {
          
          await APIRequest.update('texture-styles',this.texture_style.id,this.form)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Le style de texture a bien été modifiée !',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$router.push({name:'texture-styles'})
        } catch (error) {
          this.loading=false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Une erreur est survenue.',
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          
        }
      
        }
      })
    },
    file3DAdded(event)
    {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
          
        let b64=reader.result.split(';')
        let type=b64[0]
          
        let base64=b64[1]
        //base64.replace('base64,','')
        this.currentBase64=reader.result
        this.form.file=this.currentBase64
        this.file_3d=this.currentBase64
  
      };
    },
    fileAdded(event)
    {
      var reader = new FileReader()
      reader.readAsDataURL(event.target.files[0])
      reader.onload = () => {
          
          let b64=reader.result.split(';')
          let type=b64[0]
          if(type.includes('image'))
          {
              let base64=b64[1]
              //base64.replace('base64,','')
              this.currentBase64=reader.result
              this.form.photo=this.currentBase64
              this.file=this.currentBase64
          }
          else
          {
              this.file=null
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Ceci n'est pas une image !",
                  icon: 'AlertCircleIcon',
                  variant: 'warning',
                },
              })
          }
          
      };
    },
     
      
  },
  async mounted(){

    await this.getTextures()
    if(this.texture_style!=null)
    {
      this.form=this.texture_style
       
      this.file=this.texture_style.photo
      this.file_3d=this.texture_style.file
      this.edition=true

      if(this.texture_style.texture!=null)
      {
        for(let i=0;i<this.textureChoices.length;i++)
        {
          if(this.texture_style.texture.id == this.textureChoices[i].value)
          {
            this.texture_style.texture = this.textureChoices[i]
          }
        }
      }
    }
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>